<template>
  <div class="mb-5">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="card">
          <div class="card-header">
            Relatório - Beneficiários x Voucher Cancelado
          </div>
          <div class="card-body p-3">
            <fieldset class="border p-3 mb-1 w-100">
              <legend class="float-none w-auto lead">Filtre por</legend>
              <b-row class="mb-3">
                <b-col>
                  <b-form-group>
                    <template slot="label">
                      <strong>Tipo Relatório:</strong>
                    </template>
                    <b-form-radio-group
                      id="tipo_relatorio"
                      v-model="tipoRelatorio"
                      :options="optionsTipoRelatorio"
                      name="radio-options"
                    ></b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="md-3 12">
                  <strong>Mês/Ano de Referência:</strong>
                </b-col>
                <b-col cols="md-4 12">
                  <b-form-select
                    v-model="mes"
                    :options="meses"
                    class="form-select"
                  >
                  </b-form-select>
                </b-col>
                <b-col cols="md-1 12 text-center">
                  <span>/</span>
                </b-col>
                <b-col cols="md-4 12">
                  <the-mask
                    class="form-control"
                    type="text"
                    mask="####"
                    :masked="true"
                    v-model="ano"
                    placeholder="Ano"
                  ></the-mask>
                </b-col>
              </b-row>
              <b-row class="mt-3" align="right">
                <b-col>
                  <b-button variant="primary" @click="gerarRelatorio()"
                    ><font-awesome-icon :icon="['fas', 'file']" /> Gerar Relatório</b-button
                  >
                </b-col>
              </b-row>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helpers from "../../helpers";

export default {
  components: {},
  data() {
    return {
      items: [{ text: "Beneficiários x Voucher Cancelado", active: true }],
      tipoRelatorio: this.$store.getters.const.relatorio.TIPO.PDF,
      optionsTipoRelatorio: [
        { text: "Pdf", value: this.$store.getters.const.relatorio.TIPO.PDF },
        {
          text: "Excel",
          value: this.$store.getters.const.relatorio.TIPO.EXCEL,
        },
      ],
      meses: [
        { value: null, text: "Selecione o mês" },
        { value: 1, text: "Janeiro" },
        { value: 7, text: "Julho" },
      ],
      mes: null,
      ano: null,
    };
  },

  computed: {
    http() {
      return this.$store.getters.http;
    },
  },

  methods: {
    async gerarRelatorio() {
      try {
        this.$store.commit("setLoading", true);
        const resp = await this.http.get(
          "/admin/relatorio/voucher-cancelado",
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
            responseType: "blob",
            params: {
              ano: this.ano,
              mes: this.mes,
              tipo: this.tipoRelatorio,
            },
          }
        );
        if (resp.status == 200) {
          if (this.tipoRelatorio == "pdf") {
            helpers.downloadFile(resp.data, "relatorio_voucher_cancelado.pdf");
          } else {
            helpers.downloadFile(resp.data, "relatorio_voucher_cancelado.xlsx");
          }
        }
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.$store.commit("setLoading", false);
      }
    },
    async buscarParams() {
      try {
        let { data } = await this.http.get(`/admin/params`, {
          headers: { Authorization: `Bearer ${this.$store.getters.token}` },
        });
        this.mes = data.dados.mes;
        this.ano = data.dados.ano;
      } catch (error) {
        this.error = error;
      }
    },
  },

  mounted() {
    this.buscarParams();  
  },
};
</script>

