<template>
  <div class="mb-5">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="card">
          <div class="card-header">
            Relatório - Estudantes por Escola x Regional
          </div>
          <div class="card-body p-3">
            <fieldset class="border p-3 mb-1 w-100">
              <legend class="float-none w-auto lead">Filtre por</legend>
              <b-row>
                <b-col>
                  <b-form-group>
                    <template slot="label">
                      <strong>Tipo Relatório:</strong>
                    </template>
                    <b-form-radio-group
                      id="tipo_relatorio"
                      v-model="tipoRelatorio"
                      :options="optionsTipoRelatorio"
                      name="radio-options"
                    ></b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-3" align="right">
                <b-col>
                  <b-button variant="primary" @click="gerarRelatorio()"
                    ><font-awesome-icon :icon="['fas', 'file']" /> Gerar Relatório</b-button
                  >
                </b-col>
              </b-row>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helpers from "../../helpers";

export default {
  components: {},
  data() {
    return {
      items: [
        { text: "Relatório - Estudantes por Escola x Regional", active: true },
      ],
      tipoRelatorio: this.$store.getters.const.relatorio.TIPO.PDF,
      optionsTipoRelatorio: [
        { text: "Pdf", value: this.$store.getters.const.relatorio.TIPO.PDF },
        {
          text: "Excel",
          value: this.$store.getters.const.relatorio.TIPO.EXCEL,
        },
      ],
    };
  },

  computed: {
    http() {
      return this.$store.getters.http;
    },
  },

  methods: {
    async gerarRelatorio() {
      try {
        this.$store.commit("setLoading", true);
        const resp = await this.http.get(
          `/admin/relatorio/estudantes-por-escola-regional/${this.tipoRelatorio}`,
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
            responseType: "blob",
          }
        );
        if (resp.status == 200) {
          if (this.tipoRelatorio == "pdf") {
            helpers.downloadFile(
              resp.data,
              "relatorio_estudantes_escola_x_regional.pdf"
            );
          } else {
            helpers.downloadFile(
              resp.data,
              "relatorio_estudantes_escola_x_regional.xlsx"
            );
          }
        }
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.$store.commit("setLoading", false);
      }
    },
  },

  mounted() {},
};
</script>

