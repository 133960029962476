<template>
  <div class="mb-5">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="card">
          <div class="card-header">
            Relatório - Inconsistências Importação Arquivo Mercado
          </div>

          <div class="card-body p-3">
            <fieldset class="border p-3 m-3">
              <legend class="float-none w-auto lead">Filtre por</legend>
              <div class="row">
                <div class="col-2 text-end">
                  <b>Período:</b>
                </div>
                <div class="col">
                  <the-mask
                    class="form-control"
                    id="busca_data_importacao"
                    type="text"
                    mask="##/##/####"
                    :masked="true"
                    placeholder="Data Inicial de Importação"
                    title="Data Inicial de Importação"
                    v-model="dataImportacaoI"
                    @keyup.native.enter="gerarRelatorio"
                  ></the-mask>
                </div>
                <div class="col">
                  <the-mask
                    class="form-control"
                    id="busca_data_importacao"
                    type="text"
                    mask="##/##/####"
                    :masked="true"
                    placeholder="Data Final de Importação"
                    title="Data Final de Importação"
                    v-model="dataImportacaoF"
                    @keyup.native.enter="gerarRelatorio"
                  ></the-mask>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-2 text-end">
                  <b></b>
                </div>
                <div class="col">
                  <input
                    class="form-control"
                    id="busca_nome_arquivo"
                    type="text"
                    placeholder="Nome Arquivo"
                    title="Nome do Arquivo"
                    v-model="nomeArquivo"
                    @keyup.enter="gerarRelatorio"
                  />
                </div>
                <div class="col">
                  <b-form-select
                    class="form-select"
                    title="Situação"
                    v-model="situacao"
                    :options="optionsSituacao"
                  >
                  </b-form-select>
                </div>
              </div>
            </fieldset>
              <div class="row p-3" align="right">
                <div class="col">
                  <b-button
                    style="margin-right: 5px"
                    variant="outline-secondary"
                    @click="limpar"
                    title="Limpar filtros"
                  >
                    <font-awesome-icon :icon="['fas', 'eraser']" />
                    Limpar
                  </b-button>
                  <b-button
                    variant="primary"
                    title="Gerar Relatório"
                    @click="gerarRelatorio()"
                  >
                    <font-awesome-icon :icon="['fas', 'file-excel']" />
                    Gerar Relatório
                  </b-button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helpers from "../../helpers";

export default {
  components: {},
  data() {
    return {
      items: [
        {
          text: "Relatório - Inconsistências Importação Arquivo Mercado",
          active: true,
        },
      ],
      dataImportacaoI: null,
      dataImportacaoF: null,
      nomeArquivo: null,
      situacao: null,
      optionsSituacao: [],
    };
  },

  computed: {
    http() {
      return this.$store.getters.http;
    },
  },

  methods: {

    limpar() {
      this.dataImportacaoI = null;
      this.dataImportacaoF = null;
      this.nomeArquivo = null;
      this.situacao = null;
    },
    async gerarRelatorio() {
      try {
        this.$store.commit("setLoading", true);
        let params = {
          dataI: this.dataImportacaoI,
          dataF: this.dataImportacaoF,
          nomeArquivo: this.nomeArquivo,
          situacao: this.situacao,
        };
        const resp = await this.http.post(
          `/admin/relatorio/inconsistencias-arquivo-mercado`,
          params,
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
            responseType: "blob",
          }
        );
        helpers.downloadFile(
          resp.data,
          "relatorio_inconsistencias_arquivo_mercado.xlsx"
        );
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.$store.commit("setLoading", false);
      }
    },

    async situacaoCarga() {
      try {
        const resp = await this.http.get(
          `/admin/import/mercado/listar-situacao-carga`,
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
          }
        );
        if (resp.status == 201) {
          this.optionsSituacao.push({
            value: null,
            text: "---Inconsistência---",
          });
          resp.data.dados.forEach((element) => {
            if (element.id != 1) {
              element.value = element.id;
              element.text = element.descricao_situacao;
              this.optionsSituacao.push(element);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.situacaoCarga();
  },
};
</script>

